<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Reset Printer Counter</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto"  v-if="errorFetching || loading">
          <LoadingCard v-if="loading" />
          <ErrorCard v-if="errorFetching" message="Reset Printer Counter Not Available"/>
        </div>
        <div v-else>
          <div class=" mw-100">
            <BasicCard>
              <template slot="body">
                <div class="flex justify-between align-middle w-100 p-2">
                  <div class="ml-3 text-2xl text-left text-orange my-auto ">
                    <p class="uppercase mr-4">Printer Count</p>
                  </div>
                  <div class="ml-4 text-2xl text-left my-auto">
                    <p class="">Max Print Per Roll: {{getPrinterMaxCoupons}} </p>
                  </div>
                  <div class="mr-3 text-2xl text-left my-auto">
                    <p class="">Number of Prints Left: {{getPrinterCouponsCount}}</p>
                  </div>
                </div>
              </template>
            </BasicCard>
          </div>
          <div>
            <BasicButton title="Reset Printer Counter" class="mt-10 px-10" @click="confirmModal=true"/>
          </div>
          <div class="modules-div">
            <BasicModal v-if="confirmModal" @close="confirmModal=false">
              <template slot="body">
                <div class="w-400">
                  <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'exclamation-circle']"  />
                  <h2 class="text-orange text-3xl mb-4 uppercase">Are you sure you want to reset the printer counter?</h2>
                  <div class="flex justify-evenly">
                    <BasicButton @click="confirmModal=false" class="w-1/3" title="NO" button-background-color="bg-gray-500" />
                    <BasicButton @click="confirmReset" class="w-1/3" title="YES" />
                  </div>
                </div>
              </template>
            </BasicModal>
            <BasicModal v-if="successModal" @close="successModal=false">
              <template slot="body">
                <div class="w-400">
                  <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'check']"  />
                  <h2 class="text-orange text-4xl mb-4 uppercase">Reset Complete!</h2>
                  <div class="flex justify-evenly">
                  </div>
                </div>
              </template>
            </BasicModal>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>

import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import BasicModal from "../../components/Helpers/Modal/BasicModal";
import BasicButton from "../../components/Helpers/Buttons/BasicButton";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BasicCard from "../../components/Helpers/Cards/BasicCard";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ToastMixin from "../../mixin/ToastMixin";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ResetPrinterCounter",
  components: {LoadingCard, BasicCard, ErrorCard, BasicButton, BasicModal, BasicNav, PageTemplate},
  data(){
    return {
      loading: false,
      errorFetching: false,
      confirmModal: false,
      successModal: false,
    }
  },
  mixins: [ToastMixin],
  methods: {
    ...mapActions('Printer', ['printerCounter', 'resetPrinterCounter']),

    openSuccessModal(){
      this.successModal = true;
      setTimeout(()=> {
        this.successModal = false
      }, 3000);
    },

   async confirmReset(){
      this.loading = true;
      this.confirmModal = false
      try{
       await this.resetPrinterCounter();
        this.successModal = true;
        this.openSuccessModal();
      }catch (error){
        if(error.response.data.errors){
          error.response.data.errors.map(e =>{
            this.errorToast(`${e.field}: ${e.message}`);
          });
        }else if(error.response.data.message){
          this.errorToast(error.response.data.message);
        }else {
          this.errorToast('Opps! Not able to update form');
        }
      }finally {
        this.loading = false;
        await this.getPrinterTotal();
      }
    },
    async getPrinterTotal(){
      try{
        await this.printerCounter();
      }catch(e){
        this.errorFetching = true;
      }finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters('Printer', ['getPrinterMaxCoupons', 'getPrinterCouponsCount']),

  },
  mounted() {
     this.getPrinterTotal();
  }
}
</script>

<style scoped>

</style>